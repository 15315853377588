.h1 {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    font-size: 40px;
    width: 100%;
    font-weight: bold;
    border-radius: 7px;

}
.h1:hover {
    border: solid;
    border-radius: 7px;

}

.p1 {
    margin: 0;
    border: none;
    font-size: 20px;
    border-radius: 7px;
}
.p1:hover {
    border: solid;
    border-radius: 7px;

}

.default {
    margin: 10px;
    border: none;
    font-size: 20px;
    border-radius: 7px;

}